import React, { createContext, useState } from "react"

const defaultParticipateState = {
	showModal: false,
	setShowModal: {}
}

export const ParticipateContext = createContext(defaultParticipateState)

export const ParticipateProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);

	return (
    <ParticipateContext.Provider value={{showModal, setShowModal}}>
      {children}
    </ParticipateContext.Provider>
  );
};