/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import { CookiesProvider } from "react-cookie"
import { ParticipateProvider } from "src/contexts/participate"

export const wrapRootElement = ({ element }) => (
  <CookiesProvider>
    <ParticipateProvider>{element}</ParticipateProvider>
  </CookiesProvider>
)

export const onRouteUpdate = ({ location }) => {
  const scrollToElement = require("scroll-to-element")
  const { hash } = location
  if (hash) {
    setTimeout(scrollToElement, 500, hash, {
      offset: 0,
      duration: 500,
    })
  }
}
