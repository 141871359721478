// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-creation-js": () => import("./../src/templates/creation.js" /* webpackChunkName: "component---src-templates-creation-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-creations-js": () => import("./../src/pages/creations.js" /* webpackChunkName: "component---src-pages-creations-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policy-js": () => import("./../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-tips-js": () => import("./../src/pages/tips.js" /* webpackChunkName: "component---src-pages-tips-js" */),
  "component---src-pages-works-js": () => import("./../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */)
}

